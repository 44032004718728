/* eslint-disable react/no-unescaped-entities */
import styles from '@buddieshr/styles/components/impactBottomLine.module.scss';
import { Input, Popover, Tag, Tooltip } from 'antd';
import TextBackground from './text/textBackground';
import {
  BookTwoTone,
  FileDoneOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { useState } from 'react';
import PageWrapper from './templates/pageWrapper';
import {
  getTotalCostPerEmployee,
  numberWithComma,
} from '@buddieshr/utils/utils';
import Link from 'next/link';

const AVERAGE_SALARY = 67000;
const IMAGINE_MODE = false;

const getTotalCostTurnover = (numberOfEmployees) => {
  const totalPerEmployee = getTotalCostPerEmployee(AVERAGE_SALARY);
  return totalPerEmployee * (numberOfEmployees * 0.18);
};

const getGainProductivity = (numberOfEmployees) =>
  AVERAGE_SALARY * 0.05 * numberOfEmployees;

const getTotalImpactBottomLine = (numberOfEmployees) => {
  //productivity gain is 18%, multiplied by nb of employees and how much they are paid
  return parseFloat(
    (
      getTotalCostTurnover(numberOfEmployees) +
      getGainProductivity(numberOfEmployees)
    ).toFixed(0)
  );
};

const ImpactBottomLine = () => {
  const [numberOfEmployees, setNumberOfEmployees] = useState(50);

  return (
    <div>
      {/* <h2 className={styles.title}>
          How does BuddiesHR impact the bottom line?
        </h2> */}
      {/* <div className={styles.subtitle}>
          BuddiesHR have a substential impact on the bottom line of its
          customers by increasing employee engagement and therefore reducing
          churn and improving productivity.
        </div> */}
      <div className={styles.content}>
        {/* <Tag color="blue" className={styles.tag}>
        ROI - retention rate
      </Tag> */}

        <div className={styles.answersWrapper}>
          <h3 className={styles.surveyTitle}>
            Have you{' '}
            <TextBackground>increased the retention rate</TextBackground> in
            your company within the past 12 months thanks to BuddiesHR?
          </h3>
          <div className={styles.responses}>
            <div className={styles.itemResponse}>
              <div className={styles.gaugeGreyMedium}></div>
              <div className={styles.by}>Yes, by 1-4%</div>
            </div>
            <div className={styles.itemResponse}>
              <div className={styles.gaugeGreenLarge}></div>
              <div className={styles.by}>Yes, by 4-8%</div>
            </div>
            <div className={styles.itemResponse}>
              <div className={styles.gaugeGreyMedium1}></div>
              <div className={styles.by}>Yes, by 8+%</div>
            </div>
            <div className={styles.itemResponse}>
              <div className={styles.gaugeGreySmall}></div>
              <div className={styles.by}>No</div>
            </div>
          </div>

          <div className={styles.hintWrapper}>
            <div className={styles.hintIcon}>
              <FileDoneOutlined />
            </div>
            <div className={styles.hintContent}>
              Study done on 256 companies having used BuddiesHR apps for at
              least 1 year.
            </div>
          </div>
        </div>

        <div className={styles.savingContent}>
          <h3 className={styles.surveyTitle}>
            How much <TextBackground>money you save</TextBackground> by using
            BuddiesHR apps?
          </h3>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ marginRight: 12, fontWeight: 'bold' }}>For</div>
            <Input
              value={numberOfEmployees}
              min={30}
              size="large"
              className={styles.input}
              type="number"
              onChange={(v) => {
                setNumberOfEmployees(v.target.value);
              }}
            />
            <div style={{ fontWeight: 'bold' }}>Employees</div>
          </div>

          {IMAGINE_MODE ? (
            <div className={styles.responses}>
              <div className={styles.savingTotal}>
                <div className={styles.nbSaved} style={{ color: '#11c811' }}>
                  <span className={styles.sign}>+</span>
                  {`$${numberWithComma(
                    getGainProductivity(numberOfEmployees)
                  )}`}
                </div>
                <div className={styles.perYear}>per year</div>
                <div className={styles.nbSavedName}>
                  <div
                    style={{
                      fontSize: 14,
                      fontWeight: 'normal',
                      color: '#7e51a2',
                    }}
                  >
                    Imagine having:
                  </div>
                  <div>1% Impact on productivity</div>
                  <div className={styles.gallupSays}>
                    Gallup says engaged employees are 18% more productive. Try
                    multiplying the impact by 18?😱{' '}
                    <Popover
                      overlayStyle={{
                        width: 300,
                      }}
                      title="Impact on bottom line criteria"
                      content={
                        <div>
                          Calculations are based on an average salary of $67,000
                          (US average).
                        </div>
                      }
                    >
                      &nbsp;
                      <InfoCircleOutlined style={{ fontSize: 14 }} />
                    </Popover>
                  </div>
                </div>
              </div>

              <div className={styles.savingTotal}>
                <div className={styles.nbSaved} style={{ color: '#11c811' }}>
                  <span className={styles.sign}>+</span>
                  {`$${numberWithComma(
                    parseFloat(
                      getTotalCostTurnover(numberOfEmployees).toFixed(0)
                    )
                  )}`}
                </div>
                <div className={styles.perYear}>per year</div>
                <div className={styles.nbSavedName}>
                  <div
                    style={{
                      fontSize: 14,
                      fontWeight: 'normal',
                      color: '#7e51a2',
                    }}
                  >
                    And:
                  </div>
                  <div>1% Impact on turnover </div>
                  <div className={styles.gallupSays}>
                    Gallup says turnover is 18%-43% lower when employees are
                    engaged.{' '}
                    <Popover
                      overlayStyle={{
                        width: 300,
                      }}
                      title="Impact on bottom line criteria"
                      content={
                        <div>
                          Calculations are based on an average salary of $67,000
                          (US average).
                          <br />
                          <br />
                          US average turnover rate is 18%.
                        </div>
                      }
                    >
                      &nbsp;
                      <InfoCircleOutlined style={{ fontSize: 14 }} />
                    </Popover>
                  </div>
                </div>
              </div>
              <div className={styles.savingTotal}>
                <div className={styles.nbSaved} style={{ color: '#1b58d9' }}>
                  <span className={styles.sign}>-</span>
                  {`$${numberWithComma(numberOfEmployees * 2 * 12 * 1.6)}`}
                </div>
                <div className={styles.perYear}>per year</div>
                <div className={styles.nbSavedName}>
                  <div
                    style={{
                      fontSize: 14,
                      fontWeight: 'normal',
                      color: '#7e51a2',
                    }}
                  >
                    Then this is a no-brainer:
                  </div>
                  Cost of BuddiesHR (All Apps)
                </div>
              </div>
            </div>
          ) : (
            <div className={styles.responses}>
              <div className={styles.savingTotal}>
                <div className={styles.nbSaved} style={{ color: '#11c811' }}>
                  <span className={styles.sign}>+</span>
                  {`$${numberWithComma(
                    getTotalImpactBottomLine(numberOfEmployees)
                  )}`}
                </div>
                <div className={styles.perYear}>per year</div>
                <div className={styles.nbSavedName}>
                  <div>Impact on bottom line</div>
                  <div className={styles.gallupSays}>
                    Gallup says engaged employees are 18% more productive and
                    churn is reduced by 18-40%.
                    <Popover
                      overlayStyle={{
                        width: 300,
                      }}
                      title="Impact on bottom line criteria"
                      content={
                        IMAGINE_MODE ? (
                          <div>
                            Calculations are based on an average salary of
                            $67,000 (US average).
                          </div>
                        ) : (
                          <div>
                            Adding up:
                            <ul
                              style={{
                                listStyle: 'disc',
                                paddingInlineStart: 24,
                              }}
                            >
                              <li>
                                $
                                {numberWithComma(
                                  parseFloat(
                                    getTotalCostTurnover(
                                      numberOfEmployees
                                    ).toFixed(0)
                                  )
                                )}{' '}
                                of{' '}
                                <Link href="/cost-of-turnover">
                                  Turnover cost
                                </Link>{' '}
                                saved (for {`${numberOfEmployees} employees`})
                              </li>
                              <li>
                                $
                                {numberWithComma(
                                  parseFloat(
                                    getGainProductivity(
                                      numberOfEmployees
                                    ).toFixed(0)
                                  )
                                )}{' '}
                                of productivity gains (officially engaged
                                employees have a 18% productivity gain.
                                <br />
                                <br /> We took 5% for the sake of the
                                calculation just to keep it super realistic.
                                Just imagine with the 18% number from the
                                official study 😱.
                                <br />
                                <Link
                                  target="_blank"
                                  href="https://www.gallup.com/workplace/236927/employee-engagement-drives-growth.aspx"
                                >
                                  Source
                                </Link>
                              </li>
                            </ul>
                            <br />
                            <div>
                              Calculations are based on an average{' '}
                              <b>turnover rate of 18%</b> per year (US average),
                              and an <b>average salary of $67,000</b> (US
                              average).
                            </div>
                          </div>
                        )
                      }
                    >
                      &nbsp;
                      <InfoCircleOutlined style={{ fontSize: 14 }} />
                    </Popover>
                  </div>
                </div>
              </div>

              <div className={styles.savingTotal}>
                <div className={styles.nbSaved} style={{ color: '#1b58d9' }}>
                  <span className={styles.sign}>-</span>
                  {`$${numberWithComma(numberOfEmployees * 12 * 1.6)}`}
                </div>
                <div className={styles.perYear}>per year</div>
                <div className={styles.nbSavedName}>
                  Cost of BuddiesHR (All Apps)
                </div>
              </div>
            </div>
          )}

          <div className={styles.hintWrapper}>
            <div className={styles.hintIcon}>
              <FileDoneOutlined />
            </div>
            <div className={styles.hintContent}>
              Source: Gallup Research about{' '}
              <Link
                target="_blank"
                href="https://www.gallup.com/workplace/236927/employee-engagement-drives-growth.aspx"
              >
                Employee Engagement driving growth
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImpactBottomLine;
